.loader {
     width: 2rem;
     height: 2rem;
     border-radius: 50%;
     display: inline-block;
     border-top: 4px solid #FFF;
     border-right: 4px solid transparent;
     box-sizing: border-box;
     animation: rotation 1s linear infinite;
     margin: 0 0.5rem;
}

.loader::after {
     content: '';
     box-sizing: border-box;
     position: absolute;
     left: 0;
     top: 0;
     width: 2rem;
     height: 2rem;
     border-radius: 50%;
     border-left: 4px solid #17C3B2;
     border-bottom: 4px solid transparent;
     animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
     0% {
          transform: rotate(0deg);
     }

     100% {
          transform: rotate(360deg);
     }
}

.preLoader {
     .loader {
          width: 12rem;
          height: 12rem;
          border-top: 14px solid #0f70b7;
          border-right: 14px solid transparent;
     }

     .loader::after {
          width: 12rem;
          height: 12rem;
          border-left: 14px solid #17C3B2;
          border-bottom: 14px solid transparent;
     }
}