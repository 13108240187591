.category__control.css-1s2u09g-control,
.select__control.css-1s2u09g-control {
     border: 0;
     min-height: initial !important;
}

.css-tlfecz-indicatorContainer {
     padding: 0 1rem !important;

}

.css-1okebmr-indicatorSeparator {
     display: none;
}

.css-1pahdxg-control,
.select__control *,
.css-1pahdxg-control:hover,
.css-1okebmr-indicatorSeparator {
     border-color: transparent !important;
     box-shadow: none !important;
     border-width: 0 !important;
}

.css-1pahdxg-control,
.select__input-container {
     padding: 0 !important;
     margin: 0 !important;
     min-height: initial !important;
}

.css-319lph-ValueContainer {
     border-left: 1px solid #000 !important;
}

@media screen and (max-width : 767px) {
     .css-319lph-ValueContainer {
          border-left: 0px solid #000 !important;
     }

}

.category__control * {
     border: 0 !important;
     cursor: pointer;
     outline: none !important;
     padding: 0 !important;
}

.css-1s2u09g-control:hover {
     border-color: transparent !important;
}

.select__indicator {
     padding-top: 0 !important;
     padding-bottom: 0 !important;
}