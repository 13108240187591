@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');


*,
::after,
::before {
     box-sizing: border-box;
     outline: none;
     box-shadow: none;
}

::-webkit-scrollbar {
     width: 5px
}

::-webkit-scrollbar-thumb {
     background: #777
}

::-webkit-scrollbar-thumb:hover {
     background: #555
}

.no-scroll,
.scroll-none {
     &::-webkit-scrollbar {
          width: 0px
     }
}

html {
     font-size: 62.5%;
     scroll-behavior: smooth;
     overflow-x: hidden;
}

body {
     background: #F9F9F9;
     /* 16px */
     font-size: 1.6rem;
     overflow-y: auto;
     overflow-x: hidden;
     font-family: 'Public Sans',
          sans-serif;
}

html[dir='rtl'] {
     body {
          font-family: 'Tajawal',
               sans-serif;

     }
}

.PublicSans {
     font-family: 'Public Sans',
          sans-serif;
     font-size: 1.4rem;
     color: red;
}

.no-wrap {
     position: relative;
     text-overflow: ellipsis;
     white-space: nowrap;
     overflow: hidden;
}

#Auth,
#Introduction {

     .AddAnimalButton,
     .loginLink,
     .linksNav {
          display: none;
     }
}

@font-face {
     font-family: digital;
     src: url(../assets/fonts/DS-DIGIT.TTF);
}

.digital {
     font-family: digital !important;
}

// input[type="file"] {
//      display: none;
// }

.custom-file-upload {
     text-align: left;
     color: #ccc8;
}

@media (min-width:1023px) and (max-width:1124px) {
     .container {
          max-width: 1000px;
     }
}

@media (min-width:1280px) and (max-width:1350px) {
     .container {
          max-width: 1200px;
     }
}

@media (min-width:1536px) and (max-width:1550px) {
     .container {
          max-width: 1440px;
     }
}

@media (min-width:250px) and (max-width:767px) {
     body {
          padding-bottom: 6rem;
     }

     .QajuHzlZleFtiTUp_E8u .pOvRwHj19chJGkgemUH3 {
          padding: 0px 10px !important;
          transform: scale(0.8) !important;
     }
}

.countryIsActive::before {
     content: '';
     width: 5rem;
     height: 5rem;
     background: #f3f4f6;
     right: -30px;
     top: -10px;
     position: absolute;
     border-radius: 50%;
}

.swal2-styled.swal2-confirm {
     background: #0F70B7 !important;
     display: inline-block !important;
     border: 0 !important;
     outline: none !important;
     box-shadow: none !important;
     padding: 1rem 3rem !important;
     font-size: 1.4rem !important;
}

.InterestedIteemChecked {
     background: #0f70b7 !important;
}

.lines {
     box-orient: vertical;
     -webkit-box-orient: vertical;
     display: -webkit-box;
     overflow: hidden;
     text-overflow: ellipsis;

     &.one {
          -webkit-line-clamp: 1;
     }

     &.two {
          -webkit-line-clamp: 2;
     }

     &.three {
          -webkit-line-clamp: 3;
     }

     &.four {
          -webkit-line-clamp: 4;
     }
}

// <div className="select__control select__control--is-focused select__control--menu-is-open css-t3ipsp-control">…</div>flex
.category__control.css-13cymwt-control,
.searchFilter form * {
     box-shadow: none !important;
     border: 0 !important;
}

.css-b62m3t-container * {
     border: 0 !important;
}

.AddAnimals {
     .error {
          .css-13cymwt-control {
               border: 2px solid red !important;
          }
     }

     .css-13cymwt-control {
          border: 2px solid hsl(205deg 85% 39%) !important;
     }

     .css-b62m3t-container * {
          cursor: pointer !important;
     }

     .css-1xc3v61-indicatorContainer {
          color: hsl(205deg 85% 39%) !important;
     }

     .css-1u9des2-indicatorSeparator {
          background-color: hsl(205deg 85% 39%) !important;
     }

     .css-t3ipsp-control:hover {
          border-color: transparent !important;
     }
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
     display: unset !important;
}

.myCallContainer {
     width: 100% !important;
     height: 100% !important;
}

.QajuHzlZleFtiTUp_E8u .D9WLyEQaARfWqCTyVrpU {
     width: 100% !important;
     height: 50rem !important;
     box-shadow: none !important;
     margin-bottom: 4rem !important;
     border: 0 !important;
     padding: 0px !important;
}

.QajuHzlZleFtiTUp_E8u .sJcgXLqA2Va2eqymgksg {
     width: 50% !important;
}

.sCsSbKP9yxvw4LQAeaTz {
     min-height: 60rem !important;
}

._M8cCug8H18ALQ05cNMt,
input.TYiiRFB3EhYJGVPE4k4q,
.JtRS8dNV0vDLnpBoA6kZ,
.M4HRY2n7rpNAd1UjDNZe {
     display: none !important;
}

.QajuHzlZleFtiTUp_E8u.zzHlKp_IVmgXBPV3Jv58 {
     min-width: 100% !important;
     min-height: 100% !important;
}



.slick-prev:before,
.slick-next:before {
     color: #0f70b7 !important;
}

[dir="rtl"] {
     .dir-rtl {
          direction: rtl;
     }

     .dir-ltr-ar {
          direction: ltr;
     }
}

[dir="ltr"] {
     .dir-ltr {
          direction: ltr;
     }
}

[dir='rtl'] .slick-slide {
     float: left !important;
}

.video-react .video-react-poster {
     background-size: contain !important;
}

.reachText {
     * {
          max-width: 100%;
     }
}

.card {
     position: relative;
     perspective: 1000px;
}

.front,
.back,
.back img.access-card-front {
     position: absolute;
     transition: transform 1s;
     backface-visibility: hidden;
     transform-style: preserve-3d;
}

.valid-until {
     left: 40%;
}

.advertising-title img {
     transition: all 0.5s;
}

.advertising-title:hover img {
     opacity: 0.1;
}

.back {
     transform: rotateY(180deg);
}

.partners-card {
     padding: 5px 3px;
     text-align: center;
     // width: 80%;
     // transform: translate(-30px);
     bottom: 12rem;
     background-color: white;
     border: 2px solid #3e6f9a;
     border-radius: 12px;
     // z-index: -1;
     color: #3e6f9a;
     font-weight: 700;
     font-size: 14px;
     // right: 16%;
}

.left {
     left: 16%;
}

.right {
     right: 12%;
}

.radius-50 {
     border-radius: 50px;
}

.notification-card {
     border: 1px solid #1FC4B3;
     border-radius: 12px;
     padding: 1px 10px;
     width: 75%;
     display: flex;
     align-items: center;
}

.delete-all {
     display: inline-block;
     padding: 5px 15px;
     font-size: 20px;
     cursor: pointer;
     text-align: center;
     text-decoration: none;
     outline: none;
     border: none;
     border-radius: 10px;

     &:disabled {
          background-color: #d3d3d3;
          /* Lighter shade for disabled */
          color: #414040;
          cursor: not-allowed;
     }

     // &:hover {
     //      background-color: #c62f2f
     // }

     // &:active {
     //      box-shadow: 0 3px 12px #666;
     //      transform: translateY(4px);
     // }

}

.card:hover .front {
     transform: rotateY(180deg);
}

.card:hover .back {
     transform: rotateY(360deg);
}


.video-react .video-react-big-play-button {
     background-color: #0f70b7 !important;
}

html[dir="ltr"] {
     .rate {
          direction: rtl;
     }
}

html[dir="rtl"] {
     .rate {
          direction: ltr;
     }
}

.rate:not(:checked)>input {
     position: absolute;
     top: -9999px;
}

.rate:not(:checked)>label {
     float: left;
     width: 1em;
     overflow: hidden;
     white-space: nowrap;
     cursor: pointer;
     font-size: 50px;
     color: #ccc;
     padding-top: 2rem;
}

.rate:not(:checked)>label:before {
     content: '★ ';
}

.rate>input:checked~label {
     color: #ffc700;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
     color: #deb217;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
     color: #c59b08;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
     display: none;
}

.ShareGallery:hover {
     .shareItems {
          width: 100%;
     }
}

.AccessCardImage {
     clipPath: polygon(24% 4%, 71% 1%, 100% 28%, 100% 70%, 70% 99%, 30% 96%, 0% 62%, 0% 36%);
}

.borderIcon {
     .cls-1 {
          fill: url(#Nueva_muestra_de_degradado_11);
     }

     .cls-2 {
          fill: #231f20;
          mix-blend-mode: multiply;
          opacity: .77;
     }

     .cls-3 {
          isolation: isolate;
     }
}

.borderGradient {
     background-image: linear-gradient(to right, #dcc59e, #d3bb94, #b99b76, #a68461, #9a7654, #977250);
}

@media screen and (max-width : 1024px) {

     .QajuHzlZleFtiTUp_E8u .pOvRwHj19chJGkgemUH3,
     .QajuHzlZleFtiTUp_E8u .sJcgXLqA2Va2eqymgksg {
          width: 100% !important;
     }

     .QajuHzlZleFtiTUp_E8u .D9WLyEQaARfWqCTyVrpU {
          display: flex !important;
          flex-wrap: wrap !important;
     }

     .QajuHzlZleFtiTUp_E8u .D9WLyEQaARfWqCTyVrpU {
          height: auto !important;
          margin-bottom: 0 !important;
     }

}

@media screen and (max-width : 767px) {

     .svgMapTitle_right,
     .svgMapTitle_left {
          font-size: 1.3rem !important;
          // padding: 0 !important;
          // display: flex;
          // align-items: center;
          // justify-content: left;
     }

     // .svgMapTitle_right {
     //      justify-content: right;

     // }
}

@media screen and (max-width : 640px) {

     .svgMapTitle_right,
     .svgMapTitle_left {
          font-size: 1.4rem !important;
     }

}

img {
     pointer-events: none !important
}

.react-tel-input .selected-flag {
     padding: 0 !important;
     border-radius: 0 !important;
}

.react-tel-input .flag-dropdown {
     background-color: transparent !important;
     border: 0 !important;
     border-radius: 0 !important;
}

.react-tel-input .form-control {
     background: #FFFFFF !important;
     border: 0 !important;
     height: 20px;
}


.otpForm {
     input {
          border: 1px solid #555;
     }
}

/**
Sure, I can help you with that. Here's a simple example of a delete button created using HTML and CSS:

```html
<!DOCTYPE html>
<html>
<head>
<style>
.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #f44336;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}

.button:hover {background-color: #c62f2f}

.button:active {
  background-color: #c62f2f;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
</style>
</head>
<body>

<button class="button">Delete</button>

</body>
</html>
```

This code will create a button with the text "Delete". The button is styled with a red background color, white text, and some padding. When you hover over the button, the background color changes to a darker red. When you click on the button, it moves slightly downwards. You can modify the colors, padding, and other properties as per your requirements. Let me know if you need help with anything else! 😊

المصدر: محادثة مع Bing، 14‏/5‏/2024
(1) github.com. https://github.com/nurlailirh/Ganesha-Travel-Booking-System/tree/c956783b320f0183de020a5140ae0578bc47348e/ganeshatravel%2Fmenu.php.
(2) github.com. https://github.com/jessicaxxx/LeQuangVinh_TruongBaoNi_NguyenDuyQuoc-/tree/a5108ef0b6ab6559ef7d314d10282028d3d1f075/project%2Fweb%2Fquantri%2FxemSanPham.php.
*/