#navSlide {
     width: 10rem !important;
     // height: 50rem !important;
     overflow: hidden !important;
     overflow-y: scroll !important;
     display: inline-block;

     .slick-track {
          width: 10rem !important;

          img {
               height: 9.5rem !important;
               width: 10rem !important;
          }
     }

     .slick-slide {
          width: 100% !important;
          height: 10rem !important;
     }
}

#mainSlider {
     width: calc(100% - 11rem);
     margin-right: 0;
     margin-left: auto;
     display: inline-block;
}

// @media screen and (min-width : '1563px') {
//      #navSlide {
//           height: 70rem !important;
//      }
// }