         .textShadows {
              text-shadow: 1px 1px 5px rgb(33 34 43 / 20%);
              -webkit-text-stroke: 0.2px black;
              text-stroke: 0.2px black;
         }

         .CheckBox:hover input~.checkmark {
              background-color: #ccc;
              transform: rotate(45deg);
         }

         .CheckBox input:checked~.checkmark {
              background-color: #0F70B7;
              transform: rotate(45deg);
         }

         .checkmark:after {
              content: "";
              position: absolute;
              display: none;
         }

         .CheckBox input:checked~.checkmark:after {
              display: block;
         }